.fxs_banner_box {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
}
@media (min-width: 833px) {
	.fxs_banner_box {
		left: 255px;
	}
}
.fxs_banner_box .fa-chevron-double-up {
	display: none;
}
.fxs_banner_box.fxs_banner_box_closed .fa-chevron-double-up {
	display: inline-block;
}
.fxs_banner_box_content,
.fxs_banner_box_tab_wrapper {
	width: 100%;
	float: left;
	clear: both;
}
.fxs_banner_box_tab_wrapper_inner {
	padding-left: 20px;
}
.fxs_banner_box_tab_wrapper_inner,
.fxs_banner_box_content_inner {
	max-width: 1033px;
	margin: 0 auto;
	float: none;
	width: 100%;
}
@media (min-width: 1580px) {
	.fxs_banner_box_tab_wrapper_inner,
	.fxs_banner_box_content_inner {
		max-width: 1230px;
	}
}
.fxs_banner_box_tab,
.fxs_banner_box_content {
	background-color: #f5c861;
}
.fxs_banner_box_content {
	display: flex;
	flex-direction: column;
}
@media (min-width: 950px) {
	.fxs_banner_box_content {
		flex-direction: row;
	}
}
.fxs_banner_box_tab {
	padding: 5px 24px;
	cursor: pointer;
	float: left;
	border-radius: 22px 22px 0px 0px;
	clear: both;
	position: relative;
	bottom: -1px;
}
.fxs_banner_box_tab .fxs_banner_box_coffee_beans {
	position: absolute;
	right: 14px;
	bottom: 5px;
	width: 25px;
}
.fxs_banner_box_tab .fa-chevron-double-down,
.fxs_banner_box_tab .fa-chevron-double-up {
	width: 10px;
	color: #b38419;
}
.fxs_banner_box_tab span {
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #1b1c23;
}
.fxs_banner_box_closed .fxs_banner_box_tab .fa-chevron-double-down {
	display: none;
}
.fxs_banner_box_hidden_description .fxs_headline_from_small_to_medium {
	font-weight: 700;
	font-size: 20.8px;
	line-height: 22px;
	color: #49494f;
}
@media (min-width: 950px) {
	.fxs_banner_box_hidden_description .fxs_headline_from_small_to_medium {
		font-size: 22.4px;
		line-height: 26px;
	}
}
.fxs_banner_box_closed .fxs_banner_box_hidden_description {
	display: none;
}
.fxs_banner_box_hidden_description .fxs_entryPlain_txt {
	font-weight: 400;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 14.72px;
	line-height: 20px;
}
@media (min-width: 950px) {
	.fxs_banner_box_hidden_description .fxs_entryPlain_txt {
		font-size: 14.72px;
		line-height: 20px;
	}
}
.fxs_banner_box_content_inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	padding: 24px;
	flex-direction: column;
}
@media (min-width: 950px) {
	.fxs_banner_box_content_inner {
		flex-direction: row;
	}
}
.fxs_banner_box_closed .fxs_banner_box_content_inner {
	flex-direction: row;
}
@media (min-width: 950px) {
	.fxs_banner_box_closed .fxs_banner_box_content_inner {
		padding: 12px 24px;
		flex-direction: row;
	}
}
.fxs_banner_box_content_inner_inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}
.fxs_banner_box_closed .fxs_banner_box_content_inner_inner {
	flex-direction: row;
	flex: 0 0 100%;
}
.fxs_banner_box_title {
	display: none;
}
.fxs_banner_box_closed .fxs_banner_box_title {
	margin-bottom: 0;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 22.4px;
	line-height: 26px;
	color: #49494f;
	display: block;
}
@media (min-width: 950px) {
	.fxs_banner_box_closed .fxs_banner_box_title {
		line-height: 40px;
	}
}
.fxs_banner_box_title_description {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	color: #1b1c23;
}
@media (min-width: 950px) {
	.fxs_banner_box_title_description {
		margin-bottom: 0;
	}
}
.fxs_banner_box_closed .fxs_banner_box_title_description {
	margin-bottom: 0;
	justify-content: center;
	max-width: calc(100% - 118px);
}
.fxs_banner_box_button_cup {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 2rem;
}
@media (min-width: 950px) {
	.fxs_banner_box_button_cup {
		width: 100%;
		align-items: center;
	}
}
.fxs_banner_box_closed .fxs_banner_box_button_cup {
	width: auto;
}
.fxs_banner_box_button {
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	padding: 8px 50px;
	transition: none;
}
@media (min-width: 950px) {
	.fxs_banner_box_button {
		width: auto;
	}
}
.fxs_banner_box_button.fxs_btn_line.inverse:hover,
.fxs_banner_box_button.fxs_btn_line.inverse:active {
	background-color: #49494f;
	border-color: #1b1c23;
	color: #fff;
}
.fxs_banner_box_closed .fxs_banner_box_button {
	display: none;
}
@media (min-width: 950px) {
	.fxs_banner_box_closed .fxs_banner_box_button {
		display: block;
	}
}
.fxs_banner_coffee_cup {
	display: none;
}
.fxs_banner_box_closed .fxs_banner_coffee_cup {
	display: block;
	position: relative;
	right: -31px;
	margin-left: -31px;
	margin-top: -96px;
	bottom: -74px;
	float: right;
	width: 118px;
}
@media (min-width: 950px) {
	.fxs_banner_box_closed .fxs_banner_coffee_cup {
		width: 118px;
		margin-top: -106px;
		bottom: -74px;
	}
}
.fxs_banner_box_plans {
	display: flex;
	gap: 10px;
	justify-content: space-between;
	padding: 30px 0 0;
	position: relative;
	background-image: url(https://editorial.fxstreet.com/images/s2p/plansbackground.png);
	background-size: cover;
	background-position: center;
	width: 100%;
}
.fxs_banner_box_plans.is-single {
	width: auto;
}
@media (min-width: 950px) {
	.fxs_banner_box_plans {
		gap: 15px;
	}
}
.fxs_banner_box_closed .fxs_banner_box_plans {
	display: none;
}
.fxs_banner_box_plan {
	position: relative;
	padding: 12px;
	z-index: 2;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom left;
	filter: drop-shadow(0px 2.3348px 17.511px rgba(0, 0, 0, 0.2));
	overflow: hidden;
	border-radius: 2.81928px;
	min-height: 120px;
	cursor: pointer;
}
@media (min-width: 950px) {
	.fxs_banner_box_plan {
		padding: 18px;
	}
}
.fxs_banner_box_plan.monthly {
	background-image: url(https://editorial.fxstreet.com/images/s2p/monthlybackground.png);
}
.fxs_banner_box_plan.lifetime {
	background-image: url(https://editorial.fxstreet.com/images/s2p/lifetimebackground.png);
}
.fxs_banner_box_plan_tag {
	display: none;
}
@media (min-width: 420px) {
	.fxs_banner_box_plan_tag {
		background: #fa3f50;
		color: #eff6ff;
		position: relative;
		z-index: 2;
		margin-right: 16px;
		padding: 3px 7px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 32px;
	}
}
@media (min-width: 950px) {
	.fxs_banner_box_plan_tag {
		padding: 4px 9px;
		height: 44px;
		margin-right: 22px;
	}
}
.fxs_banner_box_plan_tag strong {
	font-weight: 800;
	white-space: nowrap;
}
.fxs_banner_box_plan_tag span {
	white-space: nowrap;
}
.monthly .fxs_banner_box_plan_tag strong {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 800;
	font-size: 11.8122px;
	line-height: 14px;
	white-space: nowrap;
}
@media (min-width: 950px) {
	.monthly .fxs_banner_box_plan_tag strong {
		font-size: 17.1191px;
		line-height: 20px;
	}
}
.monthly .fxs_banner_box_plan_tag span {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 10.9545px;
	line-height: 11px;
	white-space: nowrap;
}
@media (min-width: 950px) {
	.monthly .fxs_banner_box_plan_tag span {
		font-size: 15.876px;
		line-height: 16px;
	}
}
.lifetime .fxs_banner_box_plan_tag strong {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 800;
	font-size: 8.27948px;
	line-height: 10px;
	white-space: nowrap;
}
@media (min-width: 950px) {
	.lifetime .fxs_banner_box_plan_tag strong {
		font-size: 11.9992px;
		line-height: 14px;
	}
}
.lifetime .fxs_banner_box_plan_tag span {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 6.67677px;
	line-height: 7px;
	white-space: nowrap;
}
@media (min-width: 950px) {
	.lifetime .fxs_banner_box_plan_tag span {
		font-size: 9.67646px;
		line-height: 10px;
	}
}
.fxs_banner_box_plan_tag:after {
	content: '';
	right: -16px;
	width: 0;
	height: 0;
	position: absolute;
	border-top: 16px solid rgba(0, 0, 0, 0);
	border-bottom: 16px solid rgba(0, 0, 0, 0);
	border-left: 16px solid #fa3f50;
}
@media (min-width: 950px) {
	.fxs_banner_box_plan_tag:after {
		right: -22px;
		border-top: 22px solid rgba(0, 0, 0, 0);
		border-bottom: 22px solid rgba(0, 0, 0, 0);
		border-left: 22px solid #fa3f50;
	}
}
.fxs_banner_box_plan_tag_price {
	display: flex;
	z-index: 2;
	width: 100%;
	justify-content: flex-end;
}
@media (min-width: 420px) {
	.fxs_banner_box_plan_tag_price {
		justify-content: space-between;
	}
}
.fxs_banner_box_plan_title {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 800;
	line-height: 23px;
	text-align: center;
	color: #bdbdc2;
	z-index: 2;
	white-space: nowrap;
	font-size: 17px;
}
@media (min-width: 420px) {
	.fxs_banner_box_plan_title {
		font-size: 19.4881px;
	}
}
@media (min-width: 950px) {
	.fxs_banner_box_plan_title {
		font-size: 28.2436px;
		line-height: 33px;
	}
}
.fxs_banner_box_plan_price {
	display: flex;
	flex-direction: column;
	align-content: flex-end;
}
.fxs_banner_box_dollar_amount {
	display: flex;
	align-content: flex-start;
	justify-content: space-between;
}
.fxs_banner_box_price_dollar {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 19.0164px;
	line-height: 19px;
	text-align: center;
	color: #fff;
	margin: 5px 0 0 5px;
}
.fxs_banner_box_price_amount {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	text-align: right;
	color: #fff;
	font-size: 26.2427px;
	line-height: 26px;
}
@media (min-width: 950px) {
	.fxs_banner_box_price_amount {
		font-size: 38.0329px;
		line-height: 38px;
	}
}
.fxs_banner_box_temporality {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 7.49792px;
	line-height: 9px;
	text-align: right;
	color: #f3f3f8;
}
@media (min-width: 950px) {
	.fxs_banner_box_temporality {
		font-size: 10.8665px;
		line-height: 14px;
	}
}
.fxs_banner_box_previous_price {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	color: #fa3f50;
}
.fxs_banner_box_previous_price_dollar {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 7.49792px;
	line-height: 8px;
}
@media (min-width: 950px) {
	.fxs_banner_box_previous_price_dollar {
		font-size: 10.8665px;
		line-height: 12px;
	}
}
.fxs_banner_box_previous_price_value {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 13.1214px;
	line-height: 13px;
	text-decoration-line: line-through;
}
@media (min-width: 950px) {
	.fxs_banner_box_previous_price_value {
		font-size: 19.0164px;
		line-height: 19px;
	}
}
