.tf-v1-popover {
	display: none !important;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	height: 100vh;
	width: 100vw;
	color: #49494f;
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizelegibility;
	line-height: 1.38rem;
	overflow: hidden;
	overflow-y: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 0.33em;
	color: #1b1c23;
	letter-spacing: -0.02rem;
}
p {
	margin: 0 0 0.88em;
}
a {
	color: #e4871b;
	font-weight: 700;
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}
h2 {
	font-size: 1.46rem;
}
h3 {
	margin-bottom: 0.5rem;
	font-size: 1.34rem;
}
h4 {
	margin-bottom: 0.5rem;
	font-size: 1.2rem;
}
h5,
h6 {
	margin: 0 0 0.7em;
}
address,
cite {
	font-style: normal;
}
em {
	font-weight: 400;
}
small {
	color: #8c8d91;
	font-size: 11px;
}
sub,
sup {
	font-size: 0.8rem;
}
hr {
	height: 1px;
	margin-left: 0;
	background-color: #49494f;
	border: 0;
}
figcaption {
	font-size: 0.8rem;
	line-height: 1.063rem;
	font-weight: 400;
	margin: 0 1rem;
}
@media (min-width: 680px) {
	figcaption {
		margin: 0.5rem 0;
	}
}
figcaption small {
	display: block;
	padding-top: 0.25rem;
}
ul,
ol {
	margin-left: 1rem;
}
ul ul {
	list-style-type: circle;
}
ul ul ul {
	list-style-type: square;
}
pre {
	font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
	padding: 4px;
	background-color: rgba(221, 222, 223, 0.4);
	color: #1b1c23;
	font-size: 12px;
	overflow: auto;
	word-wrap: normal;
}
img {
	max-width: 100%;
	height: inherit;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input,
textarea,
select,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder,
:placeholder-shown {
	font-size: 0.8rem;
}
@media (min-width: 833px) {
	input,
	textarea,
	select,
	::-webkit-input-placeholder,
	::-moz-placeholder,
	:-ms-input-placeholder,
	:-moz-placeholder,
	::placeholder,
	:placeholder-shown {
		font-size: 0.92rem;
	}
	input[type='text'],
	input[type='email'],
	input[type='password'],
	input[type='search'],
	input[type='tel'],
	textarea,
	select {
		font-size: 0.92rem;
	}
}
button,
input {
	border: 0;
	outline: 0;
	border-radius: 0;
	font-family: 'Roboto', sans-serif;
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
textarea,
select {
	height: 35px;
	padding: 5px 1rem;
	background-color: #ffffff;
	border: 1px solid #a3a3a8;
	color: #49494f;
	font-size: 0.8rem;
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
textarea,
select,
input[type='text']:hover,
input[type='email']:hover,
input[type='password']:hover,
input[type='search']:hover,
input[type='tel']:hover,
textarea:hover,
select:hover,
input[type='text']:active,
input[type='email']:active,
input[type='password']:active,
input[type='search']:active,
input[type='tel']:active,
textarea:active,
select:active,
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
textarea:focus,
select:focus,
input[type='text']:visited,
input[type='email']:visited,
input[type='password']:visited,
input[type='search']:visited,
input[type='tel']:visited,
textarea:visited,
select:visited {
	box-shadow: none;
}
input[type='text']:hover,
input[type='email']:hover,
input[type='password']:hover,
input[type='search']:hover,
input[type='tel']:hover,
textarea:hover,
select:hover {
	border-color: #49494f;
}
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
textarea:focus,
select:focus {
	outline: 0;
	border-color: #e4871b;
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
textarea {
	-webkit-appearance: none;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}
input[type='checkbox'],
input[type='radio'] {
	vertical-align: top;
	margin: 5px;
}
label {
	display: inline-block;
	margin-bottom: 5px;
}
button,
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	cursor: pointer;
}
blockquote {
	position: relative;
	margin-bottom: 1rem;
	padding: 1rem 0 0.5rem 2rem;
	border: 0;
	font-style: italic;
}
blockquote,
blockquote q {
	quotes: none;
}
blockquote:before,
blockquote q:before,
blockquote:after,
blockquote q:after {
	content: '';
	content: none;
}
blockquote,
blockquote * {
	box-sizing: border-box;
}
blockquote > p:first-of-type {
	position: relative;
	z-index: 600;
}
blockquote > p:last-of-type:after {
	content: '\201D';
}
blockquote > p:first-of-type:before {
	content: '';
	position: absolute;
	top: -0.8em;
	left: -0.9em;
	z-index: -300;
	height: 3em;
	width: 3em;
	font-weight: 700;
	opacity: 0.3;
	background-image: url('https://staticcontent.fxstreet.com/website/img/blockquotes.svg');
	background-size: contain;
	background-repeat: no-repeat;
	color: #a3a3a8;
}
blockquote.blockquote_small:before {
	content: '\f10d';
	top: 0;
	left: 0;
	display: block;
	height: 2rem;
	width: 2rem;
	color: #ececf1;
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 900;
	font-variant: normal;
	text-rendering: auto;
}
blockquote footer {
	margin-bottom: 0.88em;
	color: #a3a3a8;
	font-size: 0.92rem;
	line-height: 1.25rem;
}
